import axios from '@axios';
import qs from 'qs';

export function addSections(sectionsData) {
    return axios.post('/parking-lot-sections', { data: sectionsData });
}

export function updateSections(id, sectionsData) {
    return axios.put(`/parking-lot-sections/${id}`, { data: sectionsData });
}

export function deleteSections(query = {}) {
    query = qs.stringify(query);
    return axios.delete('/parking-lot-sections/many?' + query);
}

export function deleteSection(id) {
    return axios.delete(`/parking-lot-sections/${id}`);
}
