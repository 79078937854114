import axios from '@axios';
import qs from 'qs';

export function addLots(lotData) {
    return axios.post('/parking-lot-lots', { data: lotData });
}

export function updateLots(id, lotData) {
    return axios.put(`/parking-lot-lots/${id}`, { data: lotData });
}

export function deleteLots(id) {
    return axios.delete(`/parking-lot-lots/${id}`);
}
