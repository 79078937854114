<template>
    <v-tabs class="tabs-container" hide-slider active-class="active-tab">
        <v-tab :class="$vuetify.breakpoint.smAndDown ? 'mr-1 sm' : 'mr-6'" v-if="structure.includes('floors')">{{
            $tc('Floors', 2)
        }}</v-tab>
        <v-tab :class="$vuetify.breakpoint.smAndDown ? 'mx-2 sm' : 'mx-3'" v-if="structure.includes('sections')">{{
            $tc('Sections', 2)
        }}</v-tab>
        <v-tab :class="$vuetify.breakpoint.smAndDown ? 'ml-1 sm' : 'ml-6'">{{ $tc('Spots', 2) }}</v-tab>

        <v-tab-item class="px-2" v-if="structure.includes('floors')">
            <ValidationObserver v-slot="{ handleSubmit }" :disabled="true">
                <v-form class="mt-15" @submit.prevent="handleSubmit(onAddFloor)">
                    <v-row>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$tc('Floor')" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$tc('Floor')"
                                    hide-details="auto"
                                    required
                                    v-model="floorName"
                                    name="floorName"
                                    :disabled="disabled"></v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$t('Quantity')" rules="min_value:0|required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$t('Quantity')"
                                    hide-details="auto"
                                    type="number"
                                    required
                                    v-model="floorQuantity"
                                    name="floorQuantity"
                                    :disabled="disabled"></v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="2" class="mt-1 mx-3">
                            <v-row class="mt-0">
                                <v-btn color="primary" type="submit" block :min-height="50" :disabled="disabled">
                                    <span>{{ $t('Buttons.Add') }}</span>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>

            <v-row class="mt-8" style="padding-bottom: 100px">
                <div :class="$vuetify.breakpoint.mdAndDown ? 'mobile-container' : 'desktop-container'">
                    <v-col cols="12" md="6" lg="5" v-for="(floor, index) in createdFloors" :key="index">
                        <ValidationObserver :ref="floor.name">
                            <v-sheet>
                                <v-sheet class="px-3 py-5 has-radius" :elevation="6">
                                    <v-row class="px-2">
                                        <v-col cols="4" md="4" class="d-flex align-center">
                                            <span
                                                class="black--text text-body-2 font-weight-medium"
                                                v-if="!editFloor || (editFloor && editFloorIndex != index)"
                                                >{{ floor.name }}</span
                                            >

                                            <ValidationProvider
                                                :name="$t('Floor')"
                                                rules="required"
                                                v-slot="{ errors }"
                                                v-else>
                                                <v-text-field v-model="newFloorName"></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="4" md="4" class="d-flex align-center">
                                            <span
                                                class="black--text text-body-2 font-weight-medium"
                                                v-if="!editFloor || (editFloor && editFloorIndex != index)"
                                                >{{ floor.quantity }} {{ $tc('Spots', floor.quantity) }}</span
                                            >

                                            <ValidationProvider
                                                :name="$t('Quantity')"
                                                rules="min_value:0|required"
                                                v-slot="{ errors }"
                                                v-else>
                                                <v-text-field type="number" v-model="newFloorQuantity"></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="4" md="2" offset-md="2" class="d-flex justify-end align-center">
                                            <v-dialog
                                                transition="dialog-top-transition"
                                                max-width="600"
                                                v-if="!editFloor || (editFloor && editFloorIndex != index)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                        <v-icon>
                                                            {{ mdiTrashCanOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <v-img
                                                        class="mx-auto trashcan"
                                                        width="80"
                                                        src="@/assets/icons/delete.png"></v-img>
                                                    <v-card>
                                                        <v-card-text class="text-center">
                                                            <p class="title font-weight-semibold black--text mt-12">
                                                                {{ $t('Alerts.Sure') }}
                                                            </p>
                                                        </v-card-text>
                                                        <v-card-text class="text-center">
                                                            <p class="body-2">
                                                                {{ $t('Alerts.SureDeleteFloor') }}
                                                                <strong class="black--text">{{ floor.name }}</strong>
                                                                ?<br />
                                                                {{ $t('Alerts.Irreversible') }}
                                                            </p>
                                                        </v-card-text>

                                                        <v-card-actions class="justify-end mt-4">
                                                            <v-btn
                                                                @click="
                                                                    deleteFloor(
                                                                        index,
                                                                        floor.name,
                                                                        floor.id,
                                                                        (dialog.value = false),
                                                                    )
                                                                "
                                                                color="error"
                                                                class="px-5"
                                                                >{{ $t('Buttons.Delete') }}</v-btn
                                                            >
                                                            <v-btn text @click="dialog.value = false">{{
                                                                $t('Buttons.Cancel')
                                                            }}</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </template>
                                            </v-dialog>
                                            <v-btn
                                                v-if="!editFloor || (editFloor && editFloorIndex != index)"
                                                elevation="0"
                                                @click="activateEditFloor(floor.name, floor.quantity, index)"
                                                :disabled="disabled"
                                                class="ml-2">
                                                <v-icon color="grey">
                                                    {{ mdiFileDocumentEditOutline }}
                                                </v-icon></v-btn
                                            >
                                            <v-btn
                                                v-else-if="editFloor && editFloorIndex == index"
                                                elevation="0"
                                                @click="saveEditFloor(floor.name)">
                                                <v-icon color="grey">
                                                    {{ mdiCheck }}
                                                </v-icon></v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-sheet>
                        </ValidationObserver>
                    </v-col>
                </div>
            </v-row>
        </v-tab-item>

        <v-tab-item class="px-2" v-if="structure.includes('sections')">
            <ValidationObserver v-slot="{ handleSubmit }" :disabled="true">
                <v-form class="mt-15" @submit.prevent="handleSubmit(onAddSection)">
                    <v-row>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$tc('Floor')" rules="required" v-slot="{ errors }">
                                <v-select
                                    filled
                                    shaped
                                    :items="createdFloors.length > 0 ? createdFloors : noFloorArray"
                                    :label="$tc('Floor')"
                                    :item-text="createdFloors.length > 0 ? 'name' : 'floor'"
                                    item-value="value"
                                    name="selectedFloor"
                                    v-model="sectionFloor"
                                    required
                                    hide-details="auto"
                                    :disabled="disabled"></v-select>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$tc('Section')" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$tc('Section')"
                                    hide-details="auto"
                                    required
                                    v-model="sectionName"
                                    name="sectionName"
                                    :disabled="disabled"></v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <ValidationProvider :name="$t('Quantity')" rules="min_value:0|required" v-slot="{ errors }">
                                <v-text-field
                                    filled
                                    shaped
                                    :label="$t('Quantity')"
                                    hide-details="auto"
                                    type="number"
                                    required
                                    v-model="sectionQuantity"
                                    name="sectionQuantity"
                                    :error="spotQuantityError"
                                    :disabled="disabled">
                                </v-text-field>
                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                <span v-if="spotQuantityError" class="caption error--text mb-10">{{
                                    $t('spotQuantityError')
                                }}</span>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="2" class="mt-1 mx-3">
                            <v-row class="mt-0">
                                <v-btn color="primary" type="submit" block :min-height="50" :disabled="disabled">
                                    <span>{{ $t('Buttons.Add') }}</span>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>

            <v-row class="mt-8" style="padding-bottom: 100px">
                <div :class="$vuetify.breakpoint.mdAndDown ? 'mobile-container' : 'desktop-container'">
                    <v-col cols="12" md="8" lg="6" v-for="(section, index) in createdSections" :key="index">
                        <ValidationObserver :ref="section.name">
                            <v-sheet>
                                <v-sheet class="px-3 py-5 has-radius" :elevation="6">
                                    <v-row class="px-2">
                                        <v-col cols="3" md="3" class="d-flex align-center">
                                            <span class="black--text text-body-2 font-weight-medium">{{
                                                $t(section.floor)
                                            }}</span>
                                        </v-col>
                                        <v-col cols="3" md="3" class="d-flex align-center">
                                            <span
                                                class="black--text text-body-2 font-weight-medium"
                                                v-if="!editSection || (editSection && editSectionIndex != index)"
                                                >{{ $t(section.name) }}</span
                                            >
                                            <ValidationProvider
                                                :name="$t('Section')"
                                                rules="required"
                                                v-slot="{ errors }"
                                                v-else>
                                                <v-text-field v-model="newSectionName" required></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="3" md="3" class="d-flex align-center">
                                            <span
                                                class="black--text text-body-2 font-weight-medium"
                                                v-if="!editSection || (editSection && editSectionIndex != index)"
                                                >{{ section.quantity }} {{ $tc('Spots', section.quantity) }}</span
                                            >
                                            <ValidationProvider
                                                :name="$t('Quantity')"
                                                rules="min_value:0|required"
                                                v-slot="{ errors }"
                                                v-else>
                                                <v-text-field
                                                    type="number"
                                                    :error="editSpotQuantityError"
                                                    v-model="newSectionQuantity"
                                                    required></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                <span v-if="editSpotQuantityError" class="caption error--text mb-10">{{
                                                    $t('spotQuantityError')
                                                }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="3" md="3" class="d-flex justify-end align-center">
                                            <v-dialog
                                                transition="dialog-top-transition"
                                                max-width="600"
                                                v-if="!editSection">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                        <v-icon>
                                                            {{ mdiTrashCanOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <v-img
                                                        class="mx-auto trashcan"
                                                        width="80"
                                                        src="@/assets/icons/delete.png"></v-img>
                                                    <v-card>
                                                        <v-card-text class="text-center">
                                                            <p class="title font-weight-semibold black--text mt-12">
                                                                {{ $t('Alerts.Sure') }}
                                                            </p>
                                                        </v-card-text>
                                                        <v-card-text class="text-center">
                                                            <p class="body-2">
                                                                {{ $t('Alerts.SureDeleteSection') }}
                                                                <strong class="black--text">{{ section.name }}</strong>
                                                                ?<br />
                                                                {{ $t('Alerts.Irreversible') }}
                                                            </p>
                                                        </v-card-text>

                                                        <v-card-actions class="justify-end mt-4">
                                                            <v-btn
                                                                @click="
                                                                    deleteSection(
                                                                        index,
                                                                        section.id,
                                                                        (dialog.value = false),
                                                                    )
                                                                "
                                                                color="error"
                                                                class="px-5"
                                                                >{{ $t('Buttons.Delete') }}</v-btn
                                                            >
                                                            <v-btn text @click="dialog.value = false">{{
                                                                $t('Buttons.Cancel')
                                                            }}</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </template>
                                            </v-dialog>

                                            <v-btn
                                                elevation="0"
                                                v-if="!editSection || (editSection && editSectionIndex != index)"
                                                class="ml-2"
                                                @click="
                                                    activateEditSection(
                                                        section.name,
                                                        section.quantity,
                                                        index,
                                                        section.floor,
                                                    )
                                                "
                                                :disabled="disabled">
                                                <v-icon color="grey">
                                                    {{ mdiFileDocumentEditOutline }}
                                                </v-icon>
                                            </v-btn>
                                            <v-btn v-else elevation="0" @click="saveEditSection(section.name)">
                                                <v-icon color="grey">
                                                    {{ mdiCheck }}
                                                </v-icon></v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-sheet>
                        </ValidationObserver>
                    </v-col>
                </div>
            </v-row>
        </v-tab-item>

        <v-tab-item>
            <v-sheet class="mt-15">
                <v-row style="width: 99%">
                    <v-col cols="11" md="10">
                        <v-sheet class="px-3 py-2 has-radius" :elevation="0" style="background-color: #f9f9f9">
                            <v-row class="px-6 has-radius" style="width: 100%">
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Floor') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Section') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{
                                        $tc('SpotNumber')
                                    }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Sensor') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Type') }}</span>
                                </v-col>
                                <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                    <span class="black--text text-body-1 font-weight-medium">{{ $tc('Actions') }}</span>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-row
                v-if="
                    (structure.includes('floors') && structure.includes('sections')) || structure.includes('sections')
                ">
                <div
                    :class="$vuetify.breakpoint.mdAndDown ? 'mobile-container' : 'desktop-container'"
                    style="max-height: 50vh">
                    <v-col
                        class="spots-container"
                        cols="11"
                        md="10"
                        v-for="(floor, floorIndex) in createdSections"
                        :key="floorIndex"
                        v-if="floor.spots.length > 0">
                        <div v-for="(spot, spotsIndex) in floor.spots" :key="spotsIndex">
                            <ValidationObserver :ref="spot.name">
                                <v-sheet class="px-3 mx-2 my-6 py-5 has-radius" :elevation="6">
                                    <v-row class="px-6 has-radius" style="width: 100%">
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span class="black--text text-body-1 font-weight-medium">{{
                                                spot.floor
                                            }}</span>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span class="black--text text-body-1 font-weight-medium">{{
                                                spot.section
                                            }}</span>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span
                                                class="black--text text-body-1 font-weight-medium"
                                                v-if="!spot.isEditting"
                                                >{{ spot.name }}</span
                                            >
                                            <ValidationProvider
                                                :name="$tc('SpotNumber')"
                                                rules="required"
                                                v-slot="{ errors }"
                                                v-else>
                                                <v-text-field v-model="spot.name" required></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span
                                                class="black--text text-body-1 font-weight-medium"
                                                v-if="!spot.isEditting">
                                                {{ spot.sensor }}
                                            </span>
                                            <ValidationProvider :name="$t('Sensor')" v-slot="{ errors }" v-else>
                                                <v-text-field type="number" v-model="spot.sensor"></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span class="black--text text-body-1 font-weight-medium">{{
                                                $tc('Individual')
                                            }}</span>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <v-dialog
                                                transition="dialog-top-transition"
                                                max-width="600"
                                                v-if="!spot.isEditting">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                        <v-icon>
                                                            {{ mdiTrashCanOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <v-img
                                                        class="mx-auto trashcan"
                                                        width="80"
                                                        src="@/assets/icons/delete.png"></v-img>
                                                    <v-card>
                                                        <v-card-text class="text-center">
                                                            <p class="title font-weight-semibold black--text mt-12">
                                                                {{ $t('Alerts.Sure') }}
                                                            </p>
                                                        </v-card-text>
                                                        <v-card-text class="text-center">
                                                            <p class="body-2">
                                                                {{ $t('Alerts.SureDeleteSpot') }}
                                                                <strong class="black--text">{{ spot.name }}</strong
                                                                >,
                                                                {{ $t('Section') }}
                                                                <strong class="black--text">{{ spot.section }}</strong
                                                                >,
                                                                {{ $t('Floor') }}
                                                                <strong class="black--text">{{ spot.floor }}</strong>
                                                                ?<br />
                                                                {{ $t('Alerts.Irreversible') }}
                                                            </p>
                                                        </v-card-text>

                                                        <v-card-actions class="justify-end mt-4">
                                                            <v-btn
                                                                @click="
                                                                    deleteSpot(
                                                                        floor.floor,
                                                                        floor.name,
                                                                        spotsIndex,
                                                                        spot.id,

                                                                        (dialog.value = false),
                                                                    )
                                                                "
                                                                color="error"
                                                                class="px-5"
                                                                >{{ $t('Buttons.Delete') }}</v-btn
                                                            >
                                                            <v-btn text @click="dialog.value = false">{{
                                                                $t('Buttons.Cancel')
                                                            }}</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </template>
                                            </v-dialog>

                                            <v-btn
                                                elevation="0"
                                                v-if="!spot.isEditting"
                                                class="ml-2"
                                                @click="activateEditSpot(spot.floor, spot.section, spotsIndex)"
                                                :disabled="disabled">
                                                <v-icon color="grey">
                                                    {{ mdiFileDocumentEditOutline }}
                                                </v-icon>
                                            </v-btn>

                                            <v-btn
                                                v-else
                                                elevation="0"
                                                @click="saveEditSpot(spot.floor, spot.section, spotsIndex)">
                                                <v-icon color="grey">
                                                    {{ mdiCheck }}
                                                </v-icon></v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </ValidationObserver>
                        </div>
                    </v-col>
                </div>
            </v-row>
            <v-row v-else-if="structure.includes('floors')">
                <div
                    :class="$vuetify.breakpoint.mdAndDown ? 'mobile-container' : 'desktop-container'"
                    style="max-height: 50vh">
                    <v-col
                        class="spots-container"
                        cols="11"
                        md="10"
                        v-for="(floor, floorIndex) in createdFloors"
                        :key="floorIndex"
                        v-if="floor.spots.length > 0">
                        <div v-for="(spot, spotsIndex) in floor.spots" :key="spotsIndex">
                            <ValidationObserver :ref="spot.name">
                                <v-sheet class="px-3 mx-2 my-6 py-5 has-radius" :elevation="6">
                                    <v-row class="px-6 has-radius" style="width: 100%">
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span class="black--text text-body-1 font-weight-medium">{{
                                                spot.floor
                                            }}</span>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span class="black--text text-body-1 font-weight-medium">-</span>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span
                                                class="black--text text-body-1 font-weight-medium"
                                                v-if="!spot.isEditting"
                                                >{{ spot.name }}</span
                                            >
                                            <ValidationProvider
                                                :name="$tc('SpotNumber')"
                                                rules="required"
                                                v-slot="{ errors }"
                                                v-else>
                                                <v-text-field v-model="spot.name" required></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span
                                                class="black--text text-body-1 font-weight-medium"
                                                v-if="!spot.isEditting">
                                                {{ spot.sensor }}
                                            </span>
                                            <ValidationProvider :name="$t('Sensor')" v-slot="{ errors }" v-else>
                                                <v-text-field type="number" v-model="spot.sensor"></v-text-field>
                                                <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <span class="black--text text-body-1 font-weight-medium">{{
                                                $tc('Type')
                                            }}</span>
                                        </v-col>
                                        <v-col cols="3" md="2" class="text-center d-flex align-center justify-center">
                                            <v-dialog
                                                transition="dialog-top-transition"
                                                max-width="600"
                                                v-if="!spot.isEditting">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn elevation="0" v-bind="attrs" v-on="on" :disabled="disabled">
                                                        <v-icon>
                                                            {{ mdiTrashCanOutline }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <v-img
                                                        class="mx-auto trashcan"
                                                        width="80"
                                                        src="@/assets/icons/delete.png"></v-img>
                                                    <v-card>
                                                        <v-card-text class="text-center">
                                                            <p class="title font-weight-semibold black--text mt-12">
                                                                {{ $t('Alerts.Sure') }}
                                                            </p>
                                                        </v-card-text>
                                                        <v-card-text class="text-center">
                                                            <p class="body-2">
                                                                {{ $t('Alerts.SureDeleteSpot') }}
                                                                <strong class="black--text">{{ spot.name }}</strong
                                                                >,

                                                                {{ $t('Floor') }}
                                                                <strong class="black--text">{{ spot.floor }}</strong>
                                                                ?<br />
                                                                {{ $t('Alerts.Irreversible') }}
                                                            </p>
                                                        </v-card-text>

                                                        <v-card-actions class="justify-end mt-4">
                                                            <v-btn
                                                                @click="
                                                                    deleteSpot(
                                                                        spot.floor,
                                                                        null,
                                                                        spotsIndex,
                                                                        spot.id,
                                                                        (dialog.value = false),
                                                                    )
                                                                "
                                                                color="error"
                                                                class="px-5"
                                                                >{{ $t('Buttons.Delete') }}</v-btn
                                                            >
                                                            <v-btn text @click="dialog.value = false">{{
                                                                $t('Buttons.Cancel')
                                                            }}</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </template>
                                            </v-dialog>

                                            <v-btn
                                                elevation="0"
                                                class="ml-2"
                                                v-if="!spot.isEditting"
                                                @click="activateEditSpot(spot.floor, spot.section, spotsIndex)"
                                                :disabled="disabled">
                                                <v-icon color="grey">
                                                    {{ mdiFileDocumentEditOutline }}
                                                </v-icon>
                                            </v-btn>

                                            <v-btn
                                                v-else
                                                elevation="0"
                                                @click="saveEditSpot(spot.floor, spot.section, spotsIndex)">
                                                <v-icon color="grey">
                                                    {{ mdiCheck }}
                                                </v-icon></v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </ValidationObserver>
                        </div>
                    </v-col>
                </div>
            </v-row>
        </v-tab-item>
    </v-tabs>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mdiFileDocumentEditOutline, mdiTrashCanOutline, mdiCheck } from '@mdi/js';
    import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
    import { deleteSection } from '@/api/sections';
    import { deleteLots } from '@/api/lots';
    export default {
        props: {
            structure: {
                type: Array,
                required: true,
            },

            createdStructure: {
                type: Array,
                required: false,
            },

            disabled: {
                type: Boolean,
                required: false,
            },
        },

        components: {
            ValidationProvider,
            ValidationObserver,
            PerfectScrollbar,
        },

        setup() {
            return {
                mdiFileDocumentEditOutline,
                mdiTrashCanOutline,
                mdiCheck,
            };
        },

        data() {
            return {
                floorName: '',
                floorQuantity: 0,
                createdFloors: [],
                createdSections: [],
                sectionName: '',
                sectionQuantity: 0,
                sectionFloor: null,
                noFloorArray: [],
                spotQuantityError: false,
                editFloor: false,
                savedFloorName: null,
                editSection: false,
                editSpotQuantityError: false,
                newSectionName: null,
                newSectionQuantity: 0,
                editSpot: false,
                newFloorQuantity: null,
                newFloorName: null,
                editFloorIndex: null,
                editSectionIndex: null,
                sectionToEditFloorName: null,
            };
        },

        methods: {
            //Add floor
            onAddFloor() {
                //verify if name exist in object array
                const floorExist = this.createdFloors.find((floor) => floor.name === this.floorName);

                //if floor exist, add quantity to floor capacity
                if (floorExist) {
                    floorExist.quantity = parseInt(floorExist.quantity) + parseInt(this.floorQuantity);

                    //if floor exist and structure not, add quantity to floor capacity
                    if (!this.structure.includes('sections')) {
                        floorExist.floorTotalCapacity =
                            parseInt(floorExist.floorTotalCapacity) + parseInt(this.floorQuantity);
                        floorExist.spots = [];

                        //add spots to floor
                        for (let i = 0; i < floorExist.quantity; i++) {
                            floorExist.spots.push({
                                floor: floorExist.name,
                                name: i + 1,
                                sensor: '-',
                                type: 'null',
                                isEditting: false,
                            });
                        }
                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }
                } else {
                    //if structure selected by user includes sections and floor not exist
                    if (this.structure.includes('sections')) {
                        //if floor not exist and structure includes sections, just add floor name and floor quantity
                        this.createdFloors.push({
                            name: this.floorName,
                            quantity: this.floorQuantity,
                        });
                    } else {
                        //if floor not exist and structure not includes sections, add floor name, floor quantity, floor total capacity and spots
                        this.createdFloors.push({
                            name: this.floorName,
                            quantity: this.floorQuantity,
                            spots: [],
                            floorTotalCapacity: this.floorQuantity,
                        });

                        //add spots to floor
                        for (let i = 0; i < this.createdFloors.length; i++) {
                            if (i == this.createdFloors.length - 1) {
                                for (let x = 0; x < this.createdFloors[i].quantity; x++) {
                                    this.createdFloors[i].spots.push({
                                        floor: this.createdFloors[i].name,
                                        name: x + 1,
                                        sensor: '-',
                                        type: 'null',
                                        isEditting: false,
                                    });
                                }
                            }
                        }

                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }
                }

                this.floorQuantity = 0;
            },

            //Add section
            onAddSection() {
                // verify if array includes floor name
                const floorExist = this.createdFloors.find((floor) => floor.name == this.sectionFloor);

                //if floor exist, add section to floor
                if (floorExist) {
                    // verify if sections created array contains elements
                    if (this.createdSections.length > 0) {
                        //verify if section name and floor exist in object array
                        const sectionExist = this.createdSections.find(
                            (section) => section.name === this.sectionName && section.floor === this.sectionFloor,
                        );

                        //if section exist
                        if (sectionExist) {
                            let verifyTotalSpotsCreated = 0;
                            this.createdSections.map((section) => {
                                //verify if floor name is equal to the name that user is trying to add
                                if (section.floor === this.sectionFloor) {
                                    verifyTotalSpotsCreated =
                                        parseInt(verifyTotalSpotsCreated) + parseInt(section.quantity);
                                }
                            });

                            //verify if total spots created is greater than floor quantity, if true, show error
                            if (
                                verifyTotalSpotsCreated + parseInt(this.sectionQuantity) >
                                    parseInt(floorExist.quantity) &&
                                floorExist.showOnEdit
                            ) {
                                this.spotQuantityError = true;

                                //if total spots created is less than floor quantity, add section to floor
                            } else {
                                this.spotQuantityError = false;
                                sectionExist.quantity =
                                    parseInt(sectionExist.quantity) + parseInt(this.sectionQuantity);
                                this.sectionQuantity = 0;

                                sectionExist.spots = [];

                                //add spots to section
                                for (let i = 0; i < sectionExist.quantity; i++) {
                                    sectionExist.spots.push({
                                        floor: sectionExist.floor,
                                        section: sectionExist.name,
                                        name: i + 1,
                                        sensor: '-',
                                        type: 'null',
                                        isEditting: false,
                                    });
                                }
                            }
                        } else {
                            //if section not exist, add section to floor
                            let verifyTotalSpotsCreated = 0;
                            this.createdSections.map((section) => {
                                //verify if floor name is equal to the name that user is trying to add
                                if (section.floor === this.sectionFloor) {
                                    verifyTotalSpotsCreated =
                                        parseInt(verifyTotalSpotsCreated) + parseInt(section.quantity);
                                }
                            });

                            //verify if total spots created is greater than floor quantity, if true, show error
                            if (
                                verifyTotalSpotsCreated + parseInt(this.sectionQuantity) >
                                    parseInt(floorExist.quantity) &&
                                floorExist.showOnEdit
                            ) {
                                this.spotQuantityError = true;
                            } else {
                                this.spotQuantityError = false;

                                //if total spots created is less than floor quantity, add section to floor
                                this.createdSections.push({
                                    floor: this.sectionFloor,
                                    name: this.sectionName,
                                    quantity: this.sectionQuantity,
                                    spots: [],
                                    isEditting: false,
                                    floorTotalCapacity: parseInt(floorExist.quantity),
                                });

                                this.sectionQuantity = 0;

                                //add spots to section
                                for (let i = 0; i < this.createdSections.length; i++) {
                                    if (i == this.createdSections.length - 1) {
                                        for (let x = 0; x < this.createdSections[i].quantity; x++) {
                                            this.createdSections[i].spots.push({
                                                floor: this.createdSections[i].floor,
                                                section: this.createdSections[i].name,
                                                name: x + 1,
                                                sensor: '-',
                                                type: 'null',
                                                isEditting: false,
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        //if sections created array is empty, add section to floor
                        let verifyTotalSpotsCreated = 0;
                        this.createdSections.map((section) => {
                            //verify if floor name is equal to the name that user is trying to add
                            if (section.floor === this.sectionFloor) {
                                verifyTotalSpotsCreated =
                                    parseInt(verifyTotalSpotsCreated) + parseInt(section.quantity);
                            }
                        });

                        //verify if total spots created is greater than floor quantity, if true, show error
                        if (verifyTotalSpotsCreated + parseInt(this.sectionQuantity) > parseInt(floorExist.quantity)) {
                            this.spotQuantityError = true;
                        } else {
                            //if total spots created is less than floor quantity, add section to floor
                            this.spotQuantityError = false;
                            this.createdSections.push({
                                floor: this.sectionFloor,
                                name: this.sectionName,
                                quantity: this.sectionQuantity,
                                spots: [],
                                floorTotalCapacity: parseInt(floorExist.quantity),
                            });

                            this.sectionQuantity = 0;

                            //add spots to section
                            for (let i = 0; i < this.createdSections[0].quantity; i++) {
                                this.createdSections[0].spots.push({
                                    floor: this.createdSections[0].floor,
                                    section: this.createdSections[0].name,
                                    name: i + 1,
                                    sensor: '-',
                                    type: 'null',
                                    isEditting: false,
                                });
                            }
                        }
                    }
                } else {
                    //if floor not exist, add floor
                    if (this.createdSections.length > 0) {
                        //verify if section name and floor exist in object array
                        const sectionExist = this.createdSections.find((section) => section.name === this.sectionName);

                        //if section exist, add quantity to section
                        if (sectionExist) {
                            sectionExist.quantity = parseInt(sectionExist.quantity) + parseInt(this.sectionQuantity);
                            this.sectionQuantity = 0;

                            sectionExist.spots = [];

                            //add spots to section
                            for (let i = 0; i < sectionExist.quantity; i++) {
                                sectionExist.spots.push({
                                    floor: sectionExist.floor,
                                    section: sectionExist.name,
                                    name: i + 1,
                                    sensor: '-',
                                    type: 'null',
                                    isEditting: false,
                                });
                            }
                        } else {
                            //if section not exist, add section to floor
                            this.createdSections.push({
                                floor: this.sectionFloor,
                                name: this.sectionName,
                                quantity: this.sectionQuantity,
                                spots: [],
                            });

                            //add spots to section
                            for (let i = 0; i < this.createdSections.length; i++) {
                                if (i == this.createdSections.length - 1) {
                                    for (let x = 0; x < this.createdSections[i].quantity; x++) {
                                        this.createdSections[i].spots.push({
                                            floor: this.createdSections[i].floor,
                                            section: this.createdSections[i].name,
                                            name: x + 1,
                                            sensor: '-',
                                            type: 'null',
                                            isEditting: false,
                                        });
                                    }
                                }
                            }

                            this.sectionQuantity = 0;
                        }
                    } else {
                        //if sections created array is empty, add section to floor
                        this.createdSections.push({
                            floor: this.sectionFloor,
                            name: this.sectionName,
                            quantity: this.sectionQuantity,
                            spots: [],
                            floorTotalCapacity: parseInt(this.sectionQuantity),
                        });

                        //add spots to section
                        for (let i = 0; i < this.createdSections.length; i++) {
                            for (let x = 0; x < this.createdSections[i].quantity; x++) {
                                this.createdSections[i].spots.push({
                                    floor: this.createdSections[i].floor,
                                    section: this.createdSections[i].name,
                                    name: x + 1,
                                    sensor: '-',
                                    type: 'null',
                                    isEditting: false,
                                });
                            }
                        }

                        this.sectionQuantity = 0;
                    }
                }
                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
            },

            deleteFloor(index, name, id) {
                //verify if is an update to park
                if (this.$route.params.id != 'add') {
                    //delete floor from database
                    deleteSection(id)
                        .then((response) => {
                            this.createdFloors.splice(index, 1);

                            if (this.createdSections.length > 0) {
                                let createdSectionsClone = this.createdSections.filter(
                                    (section) => section.floor !== name,
                                );
                                this.createdSections = createdSectionsClone;
                                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                            } else this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                        })
                        .catch((error) => {});
                } else {
                    //if is not an update to park, delete floor just from array
                    this.createdFloors.splice(index, 1);
                    if (this.createdSections.length > 0) {
                        let createdSectionsClone = this.createdSections.filter((section) => section.floor !== name);
                        this.createdSections = createdSectionsClone;
                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    } else this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                }
            },

            deleteSection(index, id) {
                //verify if is an update to park
                if (this.$route.params.id != 'add') {
                    //delete section from database
                    deleteSection(id)
                        .then((response) => {
                            this.createdSections.splice(index, 1);

                            this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                        })
                        .catch((error) => {
                            throw error;
                        });
                } else {
                    //if is not an update to park, delete section just from array
                    this.createdSections.splice(index, 1);
                    this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                }
            },

            //activate floor edition
            activateEditFloor(name, quantity, index) {
                this.savedFloorName = name;
                this.newFloorQuantity = quantity;
                this.newFloorName = name;
                this.editFloor = true;
                this.editFloorIndex = index;
            },

            //save floor edition changes
            async saveEditFloor() {
                //validate form
                const isValid = await this.$refs[this.savedFloorName][0].validate();

                //if form is valid, save changes
                if (isValid) {
                    //verify if structure includes sections
                    if (this.structure.includes('sections')) {
                        //map created floors array to find floor to edit on floor array
                        this.createdFloors.map((floor, index) => {
                            if (floor.name == this.savedFloorName) {
                                if (this.newFloorName != this.savedFloorName) {
                                    this.createdFloors[index].name = this.newFloorName;
                                }

                                this.createdFloors[index].quantity = this.newFloorQuantity;
                            }
                        });

                        //map created sections array to find floor to edit on section array
                        this.createdSections.map((section, index) => {
                            if (section.floor == this.savedFloorName) {
                                this.createdSections[index].floor = this.newFloorName;
                                this.createdSections[index].floorTotalCapacity = this.newFloorQuantity;
                            }
                        });

                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    } else {
                        //if structure does not include sections, just edit floor on sections
                        this.createdFloors.map((floor, index) => {
                            if (floor.name == this.savedFloorName) {
                                if (this.newFloorName != this.savedFloorName) {
                                    this.createdFloors[index].name = this.newFloorName;
                                }

                                this.createdFloors[index].quantity = this.newFloorQuantity;

                                //verify if quantity is greater than spots array length
                                if (this.createdFloors[index].quantity > this.createdFloors[index].spots.length) {
                                    //get the difference between quantity and spots array length
                                    let difference =
                                        this.createdFloors[index].quantity - this.createdFloors[index].spots.length;

                                    //create spots to fill the difference
                                    for (let i = 0; i < difference; i++) {
                                        this.createdFloors[index].spots.push({
                                            floor: this.createdFloors[index].name,
                                            section: '-',
                                            name: this.createdFloors[index].spots.length + 1,
                                            sensor: '-',
                                            type: 'null',
                                        });
                                    }
                                } else if (
                                    //verify if quantity is less than spots array length
                                    this.createdFloors[index].quantity < this.createdFloors[index].spots.length
                                ) {
                                    //get the difference between quantity and spots array length
                                    let difference =
                                        this.createdFloors[index].spots.length - this.createdFloors[index].quantity;

                                    //get the lots to remove
                                    let lotsToRemove = this.createdFloors[index].spots.slice(-difference);

                                    //delete lots from database
                                    lotsToRemove.map(async (lot) => {
                                        if (lot.id && lot.id != undefined) {
                                            await deleteLots(lot.id)
                                                .then((response) => {})
                                                .catch((error) => {
                                                    throw error;
                                                });
                                        }
                                    });

                                    //remove lots from array
                                    this.createdFloors[index].spots.splice(
                                        this.createdFloors[index].spots.length - difference,
                                        difference,
                                    );
                                }
                            }
                        });

                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }

                    this.editFloor = false;
                    this.editFloorIndex = null;
                }
            },

            //activate section edition
            activateEditSection(name, quantity, index, floor) {
                this.savedSectionName = name;
                this.newSectionName = name;
                this.newSectionQuantity = quantity;
                this.editSection = true;
                this.editSectionIndex = index;
                this.sectionToEditFloorName = floor;
            },

            //save section edition changes
            async saveEditSection() {
                //validate form
                const isValid = await this.$refs[this.savedSectionName][0].validate();

                //if form is valid, save changes
                if (isValid) {
                    //map created sections array to find section to edit
                    if (this.createdSections.length > 0) {
                        this.createdSections.map((section, index) => {
                            //if section name and floor name are the same, edit section
                            if (section.name == this.savedSectionName && section.floor == this.sectionToEditFloorName) {
                                //find floor
                                const floor = this.createdFloors.find((floor) => floor.name === section.floor);

                                //if floor exists
                                if (floor) {
                                    //verify if new section quantity is greater than floor quantity and if floor is shown to user, if so, show error
                                    if (this.newSectionQuantity > parseInt(floor.quantity) && floor.showOnEdit) {
                                        this.editSpotQuantityError = true;
                                    } else {
                                        this.editSpotQuantityError = false;
                                        this.editSection = false;

                                        //if section name is different from saved section name, change section name
                                        if (this.newSectionName != this.savedSectionName) {
                                            this.createdSections[index].name = this.newSectionName;
                                        }

                                        //change section quantity
                                        this.createdSections[index].quantity = this.newSectionQuantity;

                                        //verify if quantity is greater than spots array length
                                        if (
                                            this.createdSections[index].quantity >
                                            this.createdSections[index].spots.length
                                        ) {
                                            //get the difference between quantity and spots array length
                                            let difference =
                                                this.createdSections[index].quantity -
                                                this.createdSections[index].spots.length;

                                            //create spots to fill the difference
                                            for (let i = 0; i < difference; i++) {
                                                this.createdSections[index].spots.push({
                                                    floor: this.createdSections[index].floor,
                                                    section: this.createdSections[index].name,
                                                    name: this.createdSections[index].spots.length + 1,
                                                    sensor: '-',
                                                    type: 'null',
                                                });
                                            }
                                        }
                                        //verify if quantity is less than spots array length
                                        else if (
                                            this.createdSections[index].quantity <
                                            this.createdSections[index].spots.length
                                        ) {
                                            //get the difference between quantity and spots array length
                                            let difference =
                                                this.createdSections[index].spots.length -
                                                this.createdSections[index].quantity;

                                            //get the lots to remove
                                            let lotsToRemove = this.createdSections[index].spots.slice(-difference);

                                            //delete lots from database
                                            lotsToRemove.map(async (lot) => {
                                                if (lot.id && lot.id != undefined) {
                                                    await deleteLots(lot.id)
                                                        .then((response) => {})
                                                        .catch((error) => {
                                                            throw error;
                                                        });
                                                }
                                            });

                                            //remove lots from array
                                            this.createdSections[index].spots.splice(
                                                this.createdSections[index].spots.length - difference,
                                                difference,
                                            );
                                        }

                                        this.editSection = false;
                                        this.editSectionIndex = null;
                                    }
                                }
                                //if floor does not exist
                                else {
                                    //verify if section name is empty or null
                                    if (this.newSectionName == '' || this.newSectionName == null) {
                                        this.editSection = true;
                                    } else {
                                        //verify if section name is different from saved section name
                                        if (this.newSectionName != this.savedSectionName) {
                                            this.createdSections[index].name = this.newSectionName;
                                        }

                                        //change section quantity
                                        this.createdSections[index].quantity = this.newSectionQuantity;

                                        //verify if quantity is greater than spots array length
                                        if (
                                            this.createdSections[index].quantity >
                                            this.createdSections[index].spots.length
                                        ) {
                                            //get the difference between quantity and spots array length
                                            let difference =
                                                this.createdSections[index].quantity -
                                                this.createdSections[index].spots.length;

                                            //create spots to fill the difference
                                            for (let i = 0; i < difference; i++) {
                                                this.createdSections[index].spots.push({
                                                    floor: this.createdSections[index].floor,
                                                    section: this.createdSections[index].name,
                                                    name: this.createdSections[index].spots.length + 1,
                                                    sensor: '-',
                                                    type: 'null',
                                                });
                                            }
                                        }
                                        // verify if quantity is less than spots array length
                                        else if (
                                            this.createdSections[index].quantity <
                                            this.createdSections[index].spots.length
                                        ) {
                                            //get the difference between quantity and spots array length
                                            let difference =
                                                this.createdSections[index].spots.length -
                                                this.createdSections[index].quantity;

                                            //get the lots to remove
                                            let lotsToRemove = this.createdSections[index].spots.slice(-difference);

                                            //delete lots from database
                                            lotsToRemove.map(async (lot) => {
                                                if (lot.id && lot.id != undefined) {
                                                    await deleteLots(lot.id)
                                                        .then((response) => {})
                                                        .catch((error) => {
                                                            throw error;
                                                        });
                                                }
                                            });

                                            //remove lots from array
                                            this.createdSections[index].spots.splice(
                                                this.createdSections[index].spots.length - difference,
                                                difference,
                                            );
                                        }
                                        this.editSection = false;
                                        this.editSectionIndex = null;
                                    }
                                }
                            }
                        });

                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    }
                }
            },

            //delete spot
            async deleteSpot(floorName, sectionName, spotIndex, lotId) {
                //verify if lot id exists and is not undefined
                if (lotId && lotId != undefined) {
                    //delete lot from database
                    await deleteLots(lotId)
                        .then((response) => {
                            if (this.structure.includes('sections')) {
                                this.createdSections.map((section, index) => {
                                    if (section.floor == floorName && section.name == sectionName) {
                                        section.spots.splice(spotIndex, 1);
                                        section.quantity = parseInt(section.quantity) - 1;
                                    }
                                });
                                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                            } else {
                                this.createdFloors.map((floor, index) => {
                                    if (floor.name == floorName) {
                                        floor.spots.splice(spotIndex, 1);
                                        floor.quantity = parseInt(floor.quantity) - 1;
                                    }
                                });
                                this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                            }
                        })
                        .catch((error) => {
                            throw error;
                        });
                }
                //if lot id does not exist just remove from arrays
                else {
                    //verify if structure includes sections
                    if (this.structure.includes('sections')) {
                        //map through sections
                        this.createdSections.map((section, index) => {
                            //verify if floor and section name are the same as the ones passed as parameters
                            if (section.floor == floorName && section.name == sectionName) {
                                //remove spot from array
                                section.spots.splice(spotIndex, 1);
                                //decrease quantity
                                section.quantity = parseInt(section.quantity) - 1;
                            }
                        });
                        this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                    } else {
                        //map through floors
                        this.createdFloors.map((floor, index) => {
                            //verify if floor name is the same as the one passed as parameter
                            if (floor.name == floorName) {
                                //remove spot from array
                                floor.spots.splice(spotIndex, 1);
                                //decrease quantity
                                floor.quantity = parseInt(floor.quantity) - 1;
                            }
                        });
                        this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                    }
                }
            },

            //activate edit spot
            activateEditSpot(floorName, sectionName, spotIndex) {
                //verify if structure includes sections
                if (this.structure.includes('sections')) {
                    //map through sections
                    this.createdSections.map((section, index) => {
                        //verify if floor and section name are the same as the ones passed as parameters
                        if (section.floor == floorName && section.name == sectionName) {
                            section.spots[spotIndex].isEditting = true;
                        }
                    });
                } else {
                    //map through floors
                    this.createdFloors.map((floor, index) => {
                        //verify if floor name is the same as the one passed as parameter
                        if (floor.name == floorName) {
                            floor.spots[spotIndex].isEditting = true;
                        }
                    });
                }
            },

            //save edit spot
            saveEditSpot(floorName, sectionName, spotIndex) {
                let isValid;

                //verify if structure includes sections
                if (this.structure.includes('sections')) {
                    //map through sections
                    this.createdSections.map(async (section, index) => {
                        //verify if floor and section name are the same as the ones passed as parameters
                        if (section.floor == floorName && section.name == sectionName) {
                            //validate form
                            isValid = await this.$refs[section.spots[spotIndex].name][0].validate();

                            //if form is valid
                            if (isValid) {
                                //set isEditting to false and save changes
                                section.spots[spotIndex].isEditting = false;
                                this.$emit('getStructure', this.createdSections, 'individual', this.structure);
                            }
                        }
                    });
                } else {
                    //map through floors
                    this.createdFloors.map(async (floor, index) => {
                        //verify if floor name is the same as the one passed as parameter
                        if (floor.name == floorName) {
                            //validate form
                            isValid = await this.$refs[floor.spots[spotIndex].name][0].validate();

                            //if form is valid
                            if (isValid) {
                                //set isEditting to false and save changes
                                floor.spots[spotIndex].isEditting = false;
                                this.$emit('getStructure', this.createdFloors, 'individual', this.structure);
                            }
                        }
                    });
                }
            },
        },

        created() {
            //array to use when user does not select floor options
            this.noFloorArray.push({
                floor: this.$tc('Floor', 0) + ' ' + 0,
                value: this.$tc('Floor', 0) + ' ' + 0,
            });

            let includesFloor = this.createdStructure.filter((floor) => floor.type == 'floor');

            //if created structure is not empty
            if (this.createdStructure.length > 0) {
                //for each element in created structure
                this.createdStructure.forEach((element, index) => {
                    //if element is a floor
                    if (element.type == 'floor') {
                        //if element has children
                        if (element.children.length > 0) {
                            //push floor to created floors array
                            this.createdFloors.push({
                                name: element.name,
                                quantity: element.capacity,
                                showOnEdit: element.showOnEdit,
                                id: element.id,
                            });

                            //for each child in element, in this case, for each section in floor
                            element.children.forEach((section) => {
                                //push section to created sections array
                                this.createdSections.push({
                                    floor: element.name,
                                    name: section.name,
                                    quantity: section.capacity,
                                    spots: [],
                                    isEditting: false,
                                    floorTotalCapacity: element.capacity,
                                    id: section.id,
                                    floorId: element.id,
                                });

                                //find section in created sections array
                                let foundSection = this.createdSections.find((s, index) => s.id == section.id);

                                //get index of found section
                                let foundIndex = this.createdSections.indexOf(foundSection);

                                //if found section
                                if (foundSection) {
                                    //for each section in floor
                                    element.children.map((section) => {
                                        //for each lot in section
                                        section.lots.forEach((lot) => {
                                            //if section id is the same as found section id
                                            if (section.id == foundSection.id) {
                                                //push spot to found section
                                                this.createdSections[foundIndex].spots.push({
                                                    floor: foundSection.floor,
                                                    section: foundSection.name,
                                                    name: lot.name,
                                                    sensor: lot.sensorId,
                                                    type: 'null',
                                                    isEditting: false,
                                                    id: lot.id,
                                                });
                                            }
                                        });
                                    });
                                }
                            });
                            //if element has no children and is a floor
                        } else if (element.children.length == 0 && element.type == 'floor') {
                            //push floor to created floors array
                            this.createdFloors.push({
                                name: element.name,
                                quantity: element.capacity,
                                spots: [],
                                floorTotalCapacity: element.capacity,
                                id: element.id,
                            });

                            //for each lot in floor
                            element.lots.forEach((lot) => {
                                //push spot to created floors array
                                this.createdFloors[index].spots.push({
                                    floor: element.name,
                                    name: lot.name,
                                    sensor: lot.sensorId,
                                    type: 'null',
                                    isEditting: false,
                                    id: lot.id,
                                });
                            });
                        }
                    }
                    //if element is a section
                    else {
                        //if created structure does not include floors
                        if (includesFloor.length == 0) {
                            //if element is a section
                            if (element.type == 'section') {
                                //find section in created sections array
                                let foundSection = this.createdSections.find(
                                    (section, index) => section.id == element.id,
                                );

                                //if section is found
                                if (foundSection) {
                                    //for each lot in section and push to found section
                                    element.lots.forEach((lot) => {
                                        foundSection.spots.push({
                                            floor: foundSection.floor,
                                            section: foundSection.name,
                                            name: lot.name,
                                            sensor: lot.sensorId,
                                            type: 'null',
                                            isEditting: false,
                                            id: lot.id,
                                        });
                                    });
                                }
                            }
                        }
                    }
                });
            }
        },
    };
</script>

<style lang="scss" scoped>
    .active-tab {
        background-color: $primary !important;
        color: white !important;
    }

    .v-tab.v-tab {
        min-width: 200px;
        max-width: 200px;
        background-color: #e1e1e1;
        border-radius: 10px;
    }

    .sm {
        min-width: 100px !important;
        max-width: 50px !important;
    }

    .v-tab.v-tab .v-slide-group__prev,
    .v-tab.v-tab .v-slide-group__next {
        display: none !important;
    }

    .has-radius {
        border-radius: 16px;
    }

    .spots-container:last-child {
        padding-bottom: 20px;
    }

    .desktop-container {
        width: 98%;
        display: flex;
        flex-flow: inherit;
        overflow-y: auto;
        max-height: 30vh;
        padding-bottom: 100px;
    }

    .mobile-container {
        width: 100%;
    }
</style>
