import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

export function getUsers(query) {
    let queryString = qs.stringify(query);

    return axios.get('/users?' + queryString);
}

export function getUser(id, query) {
    let queryString = qs.stringify(query);
    return axios.get('/users/' + id + '?' + queryString);
}

export function createUser(userData) {
    return axios.post('/users', { data: userData });
}

export function updateUser(id, data) {
    let _data = _.assign({}, data);
    delete _data.id;
    _data.picture = _.get(_data, 'picture.id') ? _.get(_data, 'picture.id') : null;
    return axios.put('/users/' + id, { data: _data });
}
